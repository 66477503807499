<template>
  <div v-loading.fullscreen.lock="loading">
    <table class="w-full table-auto border-separate">
      <thead>
        <tr>
          <th class="w-1/2 text-left text-gray-400 ">Servicio</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="service in services" :key="service.id">
          <td class="text-left">
            <div class="flex flex-row items-center justify-between space-x-2">
              <div
                class="flex flex-row items-center space-x-2 cursor-pointer hover:bg-gray-50"
                @click="addService(service)">
                <span>
                  <i :class="[isServiceSelected(service)
                    ? 'bx bx-check-square text-xl text-purple-500'
                    : 'bx bx-checkbox text-xl text-gray-300']" />
                </span>
                <div>
                  <span class="block font-bold">{{ service.name }}</span>
                </div>
              </div>
              <div v-if="service.businessId === null"
                class="flex flex-row items-center space-x-2 cursor-pointer"
                @click="removeService(service)">
                <i class="bx bx-trash" />
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import ExclusionService from '@/services/ExclusionService';
import { ServiceEnumDict, ExclusionType } from '@/constants';
import error from '@/mixins/error';
import { CATALOGS_STORE } from '@/store/modules/catalogs/mutation-types';

export default {
  name: 'ServicesSearchList',
  mixins: [error],
  props: {
    services: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      loading: false,
      selectedServices: [],
      ServiceEnumDict,
    };
  },
  methods: {
    isServiceSelected(service) {
      return this.selectedServices.some((s) => s.id === service.id);
    },
    addService(service) {
      const indexOfCurrentService = this.selectedServices.findIndex((s) => s.id === service.id);
      if (indexOfCurrentService === -1) {
        service.serviceId = service.id;
        this.selectedServices.push(service);
      }
      else
        this.selectedServices.splice(indexOfCurrentService, 1);
      this.$emit('onChangeSelectedServices', this.selectedServices);
    },
    removeService(service) {
      this.$confirm(
        `¿Está seguro que desea eliminar este servicio ${service.name}?`, {
          type: 'info',
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
        }).then(async () => {
          this.loading = false;
          try {
            await ExclusionService.create({
              ExclusionType: ExclusionType.ServiceCategory,
              EntityId: service.id,
            });
            this.$toastr.s("Se elimino con exito");
            this.$store.dispatch(CATALOGS_STORE, {
              ...this.$store.state.catalogs,
              order: {
                ...this.$store.state.catalogs.order,
                service: {
                  ...this.$store.state.catalogs.order.service,
                  categories: this.$store.state.catalogs.order.service.categories
                    .filter((category) => category.id !== service.id),
                },
              },
            });
            this.$emit("onDelete", service.id);
          } catch (error) {
            const errorMessage = this.getErrorMessage(error);
            this.$toastr.e(errorMessage);
          } finally {
            this.loading = false;
          }
        });
    },
  },
};
</script>
