import HttpProxy from '@/services/HttpProxy';

export default class ExclusionService {
  static async create(data) {
    const response = await HttpProxy.submit({
      method: 'post', url: 'Exclusions', data,
    });
    return response?.data ?? null;
  }
}
